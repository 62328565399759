<template>
  <div class="text">
    <table>
    <tbody>
    <tr>
    <th>název klubu</th>
    <td class="name">Celtic Újezd u Boskovic</td>
    </tr>
    <tr>
    <th>adresa</th>
    <td>Újezd u Boskovic 66<br/>680 01 Boskovice</td>
    </tr>
    <tr>
    <th>stadion</th>
    <td>Moravan aréna<br/>Újezd u Boskovic</td>
    </tr>
    <tr>
    <th>kapacita</th>
    <td>100 míst, z toho 30 k sezení, 2 VIP sky boxy</td>
    </tr>
    <tr>
    <th>web</th>
    <td><a href="https://www.celtic-ujezd.cz/">www.celtic-ujezd.cz</a></td>
    </tr>
    <tr>
    <th>email</th>
    <td><a href="mailto:celtic2019@seznam.cz">celtic2019@seznam.cz</a></td>
    </tr>
    <tr>
    <th>telefon</th>
    <td>722 456 100</td>
    </tr>
    <tr>
    <th>klubové barvy</th>
    <td>zelená a bílá</td>
    </tr>
    <tr>
    <th>výstroj</th>
    <td><a href="https://ddsport.cz/">www.ddsport.cz</a></td>
    </tr>
    <tr>
    <th>realizační tým</th>
    <td>
    vedoucí týmu: Jan Fiala<br/>
    sportovní ředitel: Zdeněk Fabiánek<br/>
    finanční ředitelka: Lucie Včelařová<br/>
    public relations: Monika Novotná<br/>
    ředitelka fanshopu: Nikol Králová<br/>
    grafické práce: Radim Hovjacký<br/>
    technické zabezpečení: Petr Maňoušek<br/>
    technické zabezpečení: David Sehnal<br/>
    webmaster: Miroslav Prýmek<br/>
    </td>
    </tr>
    <tr>
    <th>sponzoři a partneři</th>
    <td class="partners">
    LOKO TRANS, s.r.o.<br/>
    Pivovary CZ Group, a.s.<br/>
    United Assistance a.s. <br/>
    Pohostinství Moravan, Fiala Jan, Újezd u Boskovic<br/> 
    Catering Moravan, Fiala Jan, Újezd u Boskovic<br/> 
    Tělovýchovná jednota Újezd u Boskovic<br/><br/>
    <div class="logos">
    <img style="height: 7rem" src="../assets/logo-lokotrans.png">
    <img src="../assets/logo-united-assistance.png">
    <br>
    <img style="height: 7rem" src="../assets/logo-kabaret.jpg">
    <img style="height: 7rem" src="../assets/logo-tj.jpg">
    <br>
    <img class="pivo" src="../assets/holba.png">
    <img class="pivo" src="../assets/zubr.png">
    <img class="pivo" src="../assets/litovel.png">
    </div>
    </td>
    </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Vizitka',
}
</script>

<style lang="scss" scoped>
table {
  margin-top: 2rem;
}
th {
  text-align: left;
}
span {
  color: $base;
}
td.name {
  font-weight: bold;
  font-size: 150%;
}
a {
  color: inherit;
  color: $nav-a;
}
.logos {
  text-align: center;
  img {
    height: 4rem;
    margin: 1rem;
  }
  img.pivo {
    height: 8rem;
  }
}

@media only screen and (max-width: 1000px) {
  table {
    margin: 1em 0;
  }
}
</style>