<template>
  <div class="text">
  <h1>Historie klubu</h1>

    <p>Hospoda neboli hostinec či pohostinství je restaurace; francouzský výraz
    restaurant označoval původně vydatné, občerstvující jídlo a v 70. letech 18.
    století se ve francouzštině přenesl i na hostince, v té podobě a významu jej
    následně převzala němčina. Vedle německého výrazu Restaurant se v rakouské
    němčině objevila i varianta Restauration, z níž pochází české slovo
    restaurace.
    </p>
    <h2>Místnost pro hosty (lokál)</h2>
    <p>Největší část hospody určená pro návštěvníky.
    Nacházejí se v ní stoly a židle, popřípadě vše, co by mohlo sloužit k
    pobavení návštěvníků. Obvykle to bývají hrací automaty, šipkový terč,
    kulečníkový stůl, jukebox, televizní přijímač atd.
    </p>
    <h2>Výčep</h2>
    <p>Místo ohraničené od zbytku místnosti zpravidla barem, u kterého je možno
    kratší dobu setrvat při konzumaci nápoje. Na druhé straně baru se nacházejí
    pípy sloužící k čepování nápojů, pokladna, speciální umývací dřez na
    sklenice, police na sklenice, pochutiny, cigarety atd. Do oblasti výčepu
    (čímž se rozumí oblast za barem) je vstup povolen pouze obsluze zařízení.
    </p>
    <h2>Kuchyně</h2>
    <p>
    Nachází se pouze v zařízeních nabízejících i pokrmy. Obvykle zde nalezneme 
    <b>sekret</b>áře klubu.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Partneri',
}
</script>

<style lang="scss" scoped>
.partneri {
  margin-top: 5rem;
  text-align: center;
  font-size: 150%;
}
</style>