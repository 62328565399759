<template>
  <Sezona>
<h1>Sezona 2021</h1>
<p class="perex">
Nováček z Újezdu šokoval všechny týmy i sám sebe a nečekaně postupuje do třetí
ligy z prvního místa.
</p>
<h2>4. ZEAS liga - Blanensko</h2>
<table class="stats">
<thead>
<tr><th>Pořadí</th><th>Tým</th><th>Zápasy</th><th>Výhry</th><th>Remízy</th><th>Prohry</th>
<th>Skóre pro</th><th>Skóre proti</th><th>Body</th></tr>
</thead>
<tbody>
<tr class="celtic">
<td>1</td><td class="name">CELTIC Újezd u Boskovic	</td><td>18	</td><td>15	</td><td>0	</td><td>3	</td><td>91	</td><td>23	</td><td>45</td>
</tr><tr>
<td>2</td><td class="name">FC Velenov</td><td>18</td><td>13</td><td>3</td><td>2</td><td>93</td><td>45</td><td>42</td>
</tr><tr>
<td>3</td><td class="name">MK Březová</td><td>18</td><td>9</td><td>6</td><td>3</td><td>51</td><td>37</td><td>33</td>
</tr><tr>
<td>4</td><td class="name">St.B Jabloňany</td><td>18</td><td>9</td><td>3</td><td>6</td><td>74</td><td>62</td><td>30</td>
</tr><tr>
<td>5</td><td class="name">SOKOL Chornice</td><td>18</td><td>7</td><td>5</td><td>6</td><td>56</td><td>48</td><td>26</td>
</tr><tr>
<td>6</td><td class="name">PANTHERS Vanovice</td><td>18</td><td>7</td><td>5</td><td>6</td><td>44</td><td>42</td><td>26</td>
</tr><tr>
<td>7</td><td class="name">SK Kněževes 2006</td><td>18</td><td>6</td><td>2</td><td>10</td><td>43</td><td>62</td><td>20</td>
</tr><tr>
<td>8</td><td class="name">FC Spešov</td><td>18</td><td>5</td><td>2</td><td>11</td><td>49</td><td>77</td><td>17</td>
</tr><tr>
<td>9</td><td class="name">TJ SOKOL Valchov</td><td>18</td><td>5</td><td>0</td><td>13</td><td>53</td><td>90</td><td>15</td>
</tr><tr>
<td>10</td><td class="name">FC Brťov</td><td>18</td><td>0</td><td>2</td><td>16</td><td>26</td><td>94</td><td>2</td>
</tr>
</tbody>
</table>
</Sezona>
</template>

<script>
import Sezona from '../components/Sezona.vue'

export default {
  name: 'Sezona2021',
  components: {
    Sezona,
  },
}
</script>
