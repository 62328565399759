<template>
  <div class="text">
    <h1>Pomáháme...</h1>
    <p>
    Po vzoru našeho slavného Celticu Glasgow jsme i my při založení týmu 
    přijali za svou myšlenku pomoci bližním. Zavázali jsme se proto, že 
    každou sezonu uspořádáme jednu dobročinnou akci ve prospěch osoby či
    organizace z našeho blízkého okolí.
    </p>
    <h2>2019</h2>
    <p>
    Založili jsme tradici dobročinné vánoční polévky. Pro fanoušky a
    veřejnost jsme na štědrodenní dopoledne připravili klechtanku - bezmasou
    okurkovou polévku původem z Drahanské vrchoviny. 
    </p>
  </div>
</template>
