<template>
  <Sezona>
<h1>Sezona 2020</h1>
<p class="perex">
Tato sezona nám ukázala, že náš tým má perspektivu a fotbalisté, kteří reprezentují
Celtic, zaslouženě postoupili do vyšší soutěže.
</p>
<h2>5. PÍRO DSO liga - Blanensko</h2>
<table class="stats">
<thead>
<tr><th>Pořadí</th><th>Tým</th><th>Zápasy</th><th>Výhry</th><th>Remízy</th><th>Prohry</th>
<th>Skóre pro</th><th>Skóre proti</th><th>Body</th></tr>
</thead>
<tbody>
<tr class="celtic">
<td>1</td><td class="name">CELTIC Újezd u Boskovic</td><td>16</td><td>13</td><td>1</td><td>2</td><td>53</td><td>18</td><td>40</td>
</tr><tr>
<td>2</td><td class="name">SOKOL Chornice</td><td>16</td><td>10</td><td>3</td><td>3</td><td>44</td><td>20</td><td>33</td>
</tr><tr>
<td>3</td><td class="name">OREL ROCK Boskovice</td><td>15</td><td>10</td><td>2</td><td>3</td><td>57</td><td>20</td><td>32</td>
</tr><tr>
<td>4</td><td class="name">HŘEBCI Ludíkov</td><td>16</td><td>9</td><td>3</td><td>4</td><td>43</td><td>26</td><td>30</td>
</tr><tr>
<td>5</td><td class="name">FC ARTIS Bedřichov</td><td>16</td><td>7</td><td>1</td><td>8</td><td>33</td><td>47</td><td>22</td>
</tr><tr>
<td>6</td><td class="name">FC Rozsíčka</td><td>16</td><td>6</td><td>3</td><td>7</td><td>36</td><td>29</td><td>21</td>
</tr><tr>
<td>7</td><td class="name">SHARKS Štěchov</td><td>15</td><td>6</td><td>0</td><td>9</td><td>30</td><td>32</td><td>18</td>
</tr><tr>
<td>8</td><td class="name">FC Sebranice 2002</td><td>16</td><td>5</td><td>2</td><td>9</td><td>39</td><td>57</td><td>17</td>
</tr><tr>
<td>9</td><td class="name">TJ Šerkovice „B“</td><td>16</td><td>3</td><td>1</td><td>12</td><td>29</td><td>54</td><td>10</td>
</tr><tr>
<td>10</td><td class="name">FK Jabloňany</td><td>16</td><td>2</td><td>0</td><td>14</td><td>16</td><td>77</td><td>6</td>
</tr>
</tbody>
</table>
</Sezona>
</template>

<script>
import Sezona from '../components/Sezona.vue'

export default {
  name: 'Sezona2020',
  components: {
    Sezona,
  },
}
</script>
