<template>
<Sezona>

<h1>Sezona 2019</h1>
<p class="perex">
Naše historicky první sezona dopadla nad očekávání dobře a i když se náš tým
teprve hledal, do posledních chvil bojoval o postup do vyšší soutěže. Konečné
třetí místo bylo odměnou pro celý realizační tým i samotné hráče.
</p>
<h2>5. PÍRO DSO liga - Blanensko</h2>
<table class="stats">
<thead>
<tr><th>Pořadí</th><th>Tým</th><th>Zápasy</th><th>Výhry</th><th>Remízy</th><th>Prohry</th>
<th>Skóre pro</th><th>Skóre proti</th><th>Body</th></tr>
</thead>
<tbody>
<tr>
<td>1</td><td class="name">PANTHERS Vanovice</td><td>22</td><td>18</td><td>1</td><td>3</td><td>75</td><td>31</td><td>55</td>
</tr><tr>
<td>2</td><td class="name">MK Březová</td><td>22</td><td>17</td><td>1</td><td>4</td><td>90</td><td>27</td><td>52</td>
</tr>
<tr class="celtic">
<td>3</td><td class="name">CELTIC Újezd u Boskovic</td><td>22</td><td>14</td><td>5</td><td>3</td><td>74</td><td>31</td><td>47</td>
</tr><tr>
<td>4</td><td class="name">SOKOL Chornice</td><td>22</td><td>15</td><td>2</td><td>5</td><td>93</td><td>35</td><td>47</td>
</tr><tr>
<td>5</td><td class="name">TJ Šerkovice „B“</td><td>22</td><td>14</td><td>3</td><td>5</td><td>83</td><td>40</td><td>45</td>
</tr><tr>
<td>6</td><td class="name">FC ARTIS Bedřichov</td><td>22</td><td>11</td><td>1</td><td>10</td><td>56</td><td>54</td><td>34</td>
</tr><tr>
<td>7</td><td class="name">MK BANÁNI Rájec</td><td>22</td><td>8</td><td>1</td><td>13</td><td>45</td><td>75</td><td>25</td>
</tr><tr>
<td>8</td><td class="name">STARS Letovice</td><td>22</td><td>7</td><td>3</td><td>12</td><td>67</td><td>86</td><td>24</td>
</tr><tr>
<td>9</td><td class="name">FK Obora</td><td>22</td><td>4</td><td>4</td><td>14</td><td>37</td><td>68</td><td>16</td>
</tr><tr>
<td>10</td><td class="name">SK ŽÍZEŇ Petrovice</td><td>22</td><td>5</td><td>1</td><td>16</td><td>53</td><td>96</td><td>16</td>
</tr><tr>
<td>11</td><td class="name">TJ K20 Šošůvka</td><td>22</td><td>4</td><td>2</td><td>16</td><td>32</td><td>91</td><td>14</td>
</tr><tr>
<td>12</td><td class="name">FC ROMA Olešnice</td><td>22</td><td>3</td><td>0</td><td>19</td><td>37</td><td>108</td><td>9</td>
</tr>
</tbody>
</table>

</Sezona>
</template>

<script>
import Sezona from '../components/Sezona.vue'

export default {
  name: 'Sezona2019',
  components: {
    Sezona,
  },
}
</script>
