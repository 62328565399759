<template>
  <div class="text">
    <img src="../assets/foto1.jpg">
    <img src="../assets/foto2.jpg">
    <img src="../assets/foto3.jpg">
    <img src="../assets/foto4.jpg">
  </div>
</template>

<style lang="scss" scoped>
.text {
  text-align: center;
}
img {
  width: 100%;
}
</style>