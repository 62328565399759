import { createRouter, createWebHashHistory } from 'vue-router'
import Sezona2021 from '../views/Sezona2021.vue'
import Sezona2020 from '../views/Sezona2020.vue'
import Sezona2019 from '../views/Sezona2019.vue'
import Vizitka from '../views/Vizitka.vue'
import KlubovaNej from '../views/KlubovaNej.vue'
import Historie from '../views/Historie.vue'
import Pomahame from '../views/Pomahame.vue'
import Galerie from '../views/Galerie.vue'

const routes = [
  { path: '/', redirect: '/sezony' },
  { path: '/sezony', redirect: '/sezona-2021' },
  {
    path: '/sezona-2021',
    name: 'Sezona2021',
    component: Sezona2021
  },
  {
    path: '/sezona-2020',
    name: 'Sezona2020',
    component: Sezona2020
  },
  {
    path: '/sezona-2019',
    name: 'Sezona2019',
    component: Sezona2019
  },
  {
    path: '/vizitka',
    name: 'Vizitka',
    component: Vizitka
  },
  {
    path: '/historie',
    name: 'Historie',
    component: Historie
  },
  {
    path: '/klubova-nej',
    name: 'KlubovaNej',
    component: KlubovaNej
  },
  {
    path: '/pomahame',
    name: 'pomahame',
    component: Pomahame
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: Galerie
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
