<template>
  <div class="text">
    <h1>Klubová Nej</h1>

    <h2>Největší úspěchy</h2>
    <ul>
    <li>1. místo v 5. lize Blanensko (2020)</li> 
    <li>1. místo ve 4. lize Blanensko (2021)</li> 
    <li>1. místo ve 3. lize Blanensko (2022)</li> 
    </ul>

    <h2>Nejvyšší výhra</h2>
    <ul>
    <li>13:0 FC Brťov (16.6.2021)</li> 
    </ul>

    <h2>Nejvyšší prohra</h2>
    <ul>
    <li>0:6 Panthers Vanovice (2.11.2019)</li> 
    <li>1:7 St.B Jabloňany (1.10.2022)</li> 
    </ul>

    <h2>Nejvíce branek v jednom zápase</h2>
    <ul>
    <li>4 - Michal Aujeský (17.8.2019 proti TJ K20 Šošůvka - 8:2)</li> 
    <li>4 - Aleš Henek (30.5.2021 proti TJ Sokol Valchov - 11:2)</li> 
    <li>4 - Ondřej Školař (22.5.2022 proti FC Velenov 6:2)</li> 
    </ul>

    <h2>Nejmladší střelec</h2>
    <ul>
    <li>15 let, 2 měsíce, 29 dní - Filip Sehnal (28.9.2019 proti Banáni Rájec 5:0)</li> 
    </ul>

    <h2>Nejstarší střelec</h2>
    <ul>
    <li>47 let, 1 měsíc, 0 dní - Vít Vágner (19.9.2022 proti FC Velenov 5:4)</li> 
    </ul>
  </div>
</template>

<script>
export default {
  name: 'KlubovaNej',
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 110%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.text {
  text-align: center;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}
li {
  text-align: left;
}
</style>