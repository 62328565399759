<template>
  <div id="page">
    <div id="header">
      <img id="logo" alt="Logo Celtic" src="./assets/logo-celtic.svg">
    </div>
    <div id="nav-wrapper">
      <nav>
        <router-link to="/sezony" :class="sezonyClass">sezony</router-link>
        <router-link to="/vizitka">vizitka klubu</router-link>
        <!-- <router-link to="/historie">historie</router-link> -->
        <router-link to="/klubova-nej">klubová nej</router-link>
        <router-link to="/pomahame">pomáháme</router-link>
        <router-link to="/galerie">galerie</router-link>
      </nav>
    </div>
    <router-view v-if="true || false"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    sezonyClass() {
      if (!this.$route.name) return null
      if (this.$route.name.startsWith('Sezona')) {
        return 'router-link-active'
      }
      return null
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  background-color: $bg;
  margin: 0;
  padding: 1.5rem;
  font-size: 110%;
}

h1, h2 {
  text-align: center;
}

h1, h2, h3 {
  color: $fg2;
}

a {
  text-decoration: none;
}

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  color: $fg;
}

#page {
  min-height: 95vh;
  background: #fff;
  flex: 0 1 1000px;
  box-shadow: 0 4px 10px $shadow-bg;
}

#header {
  background-image: url("assets/mic-proti-trudnomyslnosti.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: opacity(20%);
  height: 200px;
  width: 100%;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

#logo {
  height: 250px;
  position: relative;
  //top: 98px;
  top: 37px;
  left: 25px;
  filter: drop-shadow(2px 2px 2px $img-shadow-bg);
}

#nav-wrapper {
  background: darken($base, 17%);
  //box-shadow: 0 4px 2px -2px $base;
  //border-radius: 0.25rem;
  box-shadow: 0 4px 2px -2px $shadow-bg;
  height: 46px;
}

nav {
  margin-left: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  //gap: 0.5em;
  padding: 0.25em 0;

  a {
    font-size: 115%;
    color: $nav-a;
    text-align: center;
  }

  a.router-link-active,
  a.router-link-exact-active,
  a.router-link-exact-active:hover {
    color: $nav-a-active;
  }

  a:hover {
    color: $nav-a-hvr;
  }
}

#nav2 {
  margin: 15px 0;
  text-align: right;

  a {
    font-size: 90%;
    margin: 0 .5em;
    //font-weight: bold;
    color: mix(#fff,$base,20%);
    margin: auto 1rem;
  }

  a.router-link-exact-active,
  a.router-link-exact-active:hover {
    color: darken($base, 17%);
    //border-bottom: 1px dotted darken($base, 17%);
  }

  a:hover {
    color: mix(#fff,$base,60%);
  }
}

.text {
  padding: 3rem;

  h1 {
    text-align: center;
  }

  p {
    text-align: justify;
  }
}

table {
  margin: 0 auto;
  border-spacing: 0;
  border-collapse: separate;
}
tr:nth-child(even) {
  background: $tr-bg;
}
th, td {
  padding: .5em;
}

@media only screen and (max-width: 1000px) {
  body {
    padding: 0;
    font-size: 100%;
  }

  #app {
  }

  #header {
    height: 80px;
    width: 100%;
    border-radius: 0;
  }

  #logo {
    height: 125px;
    position: relative;
    top: 10px;
    left: 5px;
    filter: drop-shadow(2px 2px 2px $img-shadow-bg);
  }

  nav {
    margin-left: 75px;
    margin-right: 5px;
    flex-wrap: wrap;
    font-size: 75%;
  }

  #nav2 {
    font-size: 75%;
    text-align: center;
  }

  #page {
    flex-basis: 100vw;
  }

  .text {
    padding: 1rem;
    text-align: justify;
  }

  h1 {
    margin-top: 0.5em;
  }

  .stats {
    font-size: 60%;
  }
}
</style>
