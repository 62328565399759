<template>
  <div id="nav2">
    <router-link to="/sezona-2021">2021</router-link>
    <router-link to="/sezona-2020">2020</router-link>
    <router-link to="/sezona-2019">2019</router-link>
  </div>

  <div class="text">
  <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Sezona',
  /*
  computed: {
    year() {
      if (!this.$route.name) return '2021'
      return this.$route.name.slice(this.$route.name.length-4)
    }
  }
  */
}
</script>

<style lang="scss">
p.perex {
  font-style: italic;
  text-align: center;
  xmargin-top: 2rem;
  margin-bottom: 4rem;
}

table.stats {
  tr.celtic {
    font-weight: bold;
  }

  th, td {
    text-align: center;
  }

  th {
    border-bottom: 1px solid $th-border;
  }
  
  td.name {
    text-align: left;
  }
}
</style>

<style lang="scss" scoped>
.text {
  padding-top: 0;
}

</style>